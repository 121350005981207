import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";

export default function LinkInfo() {
    const { linkCode } = useParams();
    // const apiUrl = "https://api.maejo.link/?code=" + linkCode;
    const apiToken = "Bearer 0dd39219240c3b0db76989fdd4f0a242b2ca2fb0";
    // const [linkUrl, setLinkUrl] = useState('');
    //   const location = useLocation();
    const [linkInfo, setLinkInfo] = useState([]);
    const [dataLoad, setDataLoad] = useState(false);

    const instance = axios.create({
        baseURL: "https://api.maejo.link/link/?code=",
        headers: {
            Authorization: apiToken,
        },
    });

    useEffect(() => {
        instance.get(linkCode).then((res) => {
            if (res.data) {
                setLinkInfo(res.data);
                setDataLoad(true);
                console.log("linkInfo:", res.data);
            }
        }, dataLoad);
        // eslint-disable-next-line
    }, []);

    if (dataLoad) {
        console.log("link OK");
    } else {
        console.log("link not Found!");
    }

    // linkUrl && window.location.replace(linkUrl)
    const PageHeader = () => {
        return <div id="pageHeadingHolder" className="text-center">
            <h1 className="textShadow">maejo.link</h1>
        </div>
    }

    const PageFooter = () => {
        return <div id="pageFooterHolder" className="text-center mt-auto">
            {/* <div className="d-flex flex-row justify-content-between"> */}
            <p>
            © Copyright <span className="fw-bold">Maejo University</span>. All Rights Reserved
            </p>
            {/* </div> */}
        </div>
    }

    const PageLinkInfo = () => {
        const qrCodeSize = 250;

        return <div id="pageLinkInfo" className="container text-center py-4 mx-auto">
            <div>
                <div className="linkCode">{`https://maejo.link/${linkInfo.links_code}`}</div>
                <div className="linkQr">
                    <QRCodeCanvas
                        value={`https://maejo.link/${linkInfo.links_code}`}
                        size={qrCodeSize}
                        bgColor={"#ffffff"}
                        fgColor={"#052b0f"}
                        level={"H"}
                        includeMargin={true}
                        imageSettings={{
                            src: "https://maejo.link/apple-touch-icon.png",
                            height: (qrCodeSize * 0.2),
                            width: (qrCodeSize * 0.2),
                            excavate: true,
                        }}
                    />
                </div>
                <div className="linkTitle">{linkInfo.links_title}</div>
                <div className="linkCreateBy">สร้างลิงก์โดย</div>
                <div className="linkUser">{`${linkInfo.links_user} / ${linkInfo.links_department}`}</div>
            </div>
        </div>
    }


    return (
        <div id="linkInfo" className="d-flex flex-column min-vh-100">
            <PageHeader />
            <PageLinkInfo />
            <PageFooter />
        </div>
    )

    // return (
    //   <>
    //     {/* <h1>Links Redirector</h1> */}
    //     {/* <h3>{linkCode}</h3> */}

    //     {/* <p>{linkUrl}</p> */}

    //   </>
    // );
}
