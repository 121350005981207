import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga";

export default function Links() {
  const { linkCode } = useParams();
  // const apiUrl = "https://api.maejo.link/?code=" + linkCode;
  const apiToken = "Bearer 0dd39219240c3b0db76989fdd4f0a242b2ca2fb0";
  // const [linkUrl, setLinkUrl] = useState('');
  const location = useLocation();

  const instance = axios.create({
    baseURL: "https://api.maejo.link/?code=",
    headers: {
      Authorization: apiToken,
    },
  });

  useEffect(() => {
    instance.get(linkCode).then((res) => {
      if (res.data) {
        // console.log("linkcode:", linkCode);
        // console.log("linkUrl:", res.data);
        // console.log("window.location.replace:", res.data);

        ReactGA.initialize("G-94BF06KK8G");
        ReactGA.pageview(location.pathname + location.search);

        window.location.replace(res.data);

      } else {
        window.location.replace("/?alert=warning&msg=ไม่พบข้อมูลลิงก์ที่ระบุ");
      }
      // setLinkUrl(res.data);
    });

    // if (linkUrl) {

    // }

    // eslint-disable-next-line
  });

  // linkUrl && window.location.replace(linkUrl)

  // return (
  //   <>
  //     {/* <h1>Links Redirector</h1> */}
  //     {/* <h3>{linkCode}</h3> */}

  //     {/* <p>{linkUrl}</p> */}

  //   </>
  // );
}
