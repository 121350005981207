import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "sweetalert2/dist/sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import { ModalSignForm } from "../components/form/signIn";
import "../assets/css/homeStyle.css";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
    Header,
    HeroTitle,
    Contents,
    Footer,
} from "../components/Home_element";

export default function Homepage() {
    const [setCookie] = useCookies(["username"]);
    if (!useCookies(["username"])) {
        setCookie(null);
    }

    const navigate = useNavigate();
    const [linkCode, setLinkCode] = useSearchParams();

    if (sessionStorage.getItem("alert_icon")) {
        const appAlert = {
            icon: sessionStorage.getItem("alert_icon"),
            title: sessionStorage.getItem("alert_title"),
            text: sessionStorage.getItem("alert_text"),
        };
        // sessionStorage.removeItem("alert_icon");
        // sessionStorage.removeItem("alert_text");
        // sessionStorage.removeItem("alert_title");
        sessionStorage.clear();
        alert(appAlert.title);
    }

    useEffect(() => {
        if (linkCode && linkCode.get("L")) {
            console.log("query param:", linkCode.get("L"));
            navigate("/" + linkCode.get("L"));
        }

        if (linkCode && linkCode.get("alert") && linkCode.get("msg")) {
            withReactContent(Swal).fire({
                title: "ขออภัย",
                text: linkCode.get("msg"),
                icon: linkCode.get("alert"),
            });
        }

        AOS.init();

        // eslint-disable-next-line
    }, []);

    return (
        <>
            {/* <ModalSignForm userName={cookies.username} /> */}
            <Header />
            <HeroTitle />
            <Contents />
            {/* <h1 className="tomtest">Homepage</h1> */}
            <Footer />

            {/* <div id="preloader"></div> */}
            {/* <a href="#b" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i>ab</a> */}
        </>
    );
}
