import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Homepage from './pages/Homepage';
import Links from './pages/Links';
import LinkInfo from './pages/LinksInfo';
// import MemberZone from './pages/MemberZone';
// import MyForm from './components/form/sample';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/:linkCode",
    element: <Links />,
  },
  {
    path: "/:linkCode/info",
    element: <LinkInfo />,
  },
  {
    path: "/info/:linkCode",
    element: <LinkInfo />,
  },
  // {
  //   path: "/sign",
  //   element: <Homepage />,
  // },
  // {
  //   path: "/mjuPassport",
  //   element: <Homepage />,
  // },
  // {
  //   path: "/sampleform",
  //   element: <MyForm />,
  // },
  // {
  //   path: "/member",
  //   element:<Navigate to="/" />
  // },
  // {
  //   path: "/member/:memberToken",
  //   element: <MemberZone />,
  // }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <>
    <RouterProvider router={router} />
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
